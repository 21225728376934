import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemAvatar,
  Avatar,
  Link,
  ListItemText,
  Typography,
  Grid
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  listItem: {
    fontSize: "30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: theme.spacing(3)
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(3)
  }
}));

// const authors = [
//   {
//     name: "Author 1",
//     avatar: "/path/to/avatar1.jpg",
//     affiliations: ["Affiliation 1", "Affiliation 2"]
//   },
//   {
//     name: "Author 2",
//     avatar: "/path/to/avatar2.jpg",
//     affiliations: ["Affiliation 3", "Affiliation 4"]
//   }
// ];

function AuthorsList(props) {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      {props.authors.map((author, index) => (
        <Grid item key={index} xs={12} sm={4} md={4} className={classes.listItem}>
          <ListItemAvatar>
            <Avatar src={author.avatar} className={classes.avatar} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link href={author.homepage} style={{ fontSize: "18px", fontWeight: "normal" }}>{author.name}</Link>
            }
            secondary={
              <React.Fragment>
                {author.affiliations.map((affiliation, index) => (
                  <Typography
                    key={index}
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {affiliation} <br />
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default AuthorsList;