import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';


const animatedComponents = makeAnimated();


class Viewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image_list: { '1': { 'Chunk_1_1_0': { '1': { '0': 'd.png' } } } },
      area: '1',
      subArea: 'Chunk_1_1_0',
      angle: '0',
      stageA: '2',
      stageB: '3',
      stageOption: [{ value: "2", label: "Stage 2" }, { value: "3", label: "Stage 3" }],
      frontView: true,
      includeCeiling: false,
      folder: "chunk/",
      imageA: process.env.PUBLIC_URL + '/images/chunk/Bldg_1_Stg_2_Chunk_1_1_0AncStg_3_CoorRatio_0.808_0.png',
      imageB: process.env.PUBLIC_URL + '/images/chunk/Bldg_1_Stg_3_Chunk_1_1_0AncStg_3_CoorRatio_1.000_0.png',
    };
  }

  componentDidMount() {
    this.loadFileList();
  }

  loadFileList() {
    var self = this;
    fetch(process.env.PUBLIC_URL + '/images/image_list.json')
      .then(response => response.json())
      .then((jsonData) => {
        self.setState({ image_list: jsonData });
        console.log(jsonData);
      })
      .catch((error) => {
        console.error(error)
      })
  };

  getOptionsArea() {
    var array = Object.keys(this.state.image_list);
    var result = Array(array.length);
    const areaName = ['A', 'B', 'C', 'D', 'E', 'F'];
    array.forEach((element, i) => {
      result.push({ value: element, label: "Area " + areaName[i] });
    });
    return result;
  }

  toOptionsArea() {
    var element = this.state.area;
    var array = Object.keys(this.state.image_list);
    var i = array.indexOf(element);
    const areaName = ['A', 'B', 'C', 'D', 'E', 'F'];
    return { value: element, label: "Area " + areaName[i] };
  }

  setArea(option) {
    this.setState({ area: option.value });
    var array = Object.keys(this.state.image_list[option.value]);
    this.setState({ subArea: array[0] });
    var array2 = Object.keys(this.state.image_list[option.value][array[0]]);
    console.log(this.state);
    if (array2.length >= 2) {
      this.setState({ stageA: array2[0], stageB: array2[1] });
      this.setState({ stageOption: [{ value: array2[0], label: "Stage " + array2[0] }, { value: array2[1], label: "Stage " + array2[1] }] });
      this.setState({
        imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[option.value][array[0]][array2[0]][this.state.angle],
        imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[option.value][array[0]][array2[1]][this.state.angle]
      });
    } else {
      this.setState({ stageA: array2[0], stageB: array2[0] });
      this.setState({ stageOption: [{ value: array2[0], label: "Stage " + array2[0] }, { value: array2[0], label: "Stage " + array2[0] }] });
      this.setState({
        imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[option.value][array[0]][array2[0]][this.state.angle],
        imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[option.value][array[0]][array2[0]][this.state.angle]
      });
    }
  }


  getOptionsSubArea() {
    console.log(this.state.area)
    var array = Object.keys(this.state.image_list[this.state.area]);
    var result = Array(array.length);
    array.forEach((element, i) => {
      result.push({ value: element, label: "Sub-area " + (4 * i + 1).toString() + " - " + (4 * i + 4).toString() });
    });
    return result;
  }

  toOptionsSubArea() {
    var element = this.state.subArea;
    var array = Object.keys(this.state.image_list[this.state.area]);
    var i = array.indexOf(element);
    return { value: element, label: "Sub-area " + (4 * i + 1).toString() + " - " + (4 * i + 4).toString() };
  }

  setSubArea(option) {
    this.setState({ subArea: option.value });
    var array = Object.keys(this.state.image_list[this.state.area][option.value]);
    if (array.length >= 2) {
      this.setState({ stageA: array[0], stageB: array[1] });
      this.setState({ stageOption: [{ value: array[0], label: "Stage " + array[0] }, { value: array[1], label: "Stage " + array[1] }] });
      this.setState({
        imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][option.value][array[0]][this.state.angle],
        imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][option.value][array[1]][this.state.angle]
      });
    } else {
      this.setState({ stageA: array[0], stageB: array[0] });
      this.setState({ stageOption: [{ value: array[0], label: "Stage " + array[0] }, { value: array[0], label: "Stage " + array[0] }] });
      this.setState({
        imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][option.value][array[0]][this.state.angle],
        imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][option.value][array[0]][this.state.angle]
      });
    }
  }

  getOptionsStage() {
    console.log(this.state.area)
    var array = Object.keys(this.state.image_list[this.state.area][this.state.subArea]);
    var result = Array(array.length);
    array.forEach((element, i) => {
      result.push({ value: element, label: "Stage " + element });
    });
    return result;
  }

  setStage(option) {
    console.log(option);
    var option_new = option.slice(Math.max(option.length - 2, 0));
    this.setState({ stageOption: option_new });

    if (option_new.length == 2) {
      this.setState({
        stageA: option_new[0].value,
        stageB: option_new[1].value,
        imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][option_new[0].value][this.state.angle],
        imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][option_new[1].value][this.state.angle]
      });
    } else if (option_new.length == 1) {
      this.setState({
        stageA: option_new[0].value,
        stageB: option_new[0].value,
        imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][option_new[0].value][this.state.angle],
        imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][option_new[0].value][this.state.angle]
      });
    }
  }

  getOptionsAngle() {
    var array = Object.keys(this.state.image_list[this.state.area][this.state.subArea][this.state.stage]);
    var result = Array(array.length);
    const areaName = ['Front', 'Back'];
    array.forEach((element, i) => {
      result.push({ value: element, label: areaName[i] });
    });
    return result;
  }

  setAngle(option) {
    this.setState({
      angle: option.value,
      imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][this.state.stageA][option.value],
      imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][this.state.stageB][option.value]
    });
    console.log(this.state);
  }

  changeView(option) {
    var newView, newAngle;
    if (this.state.frontView === true) {
      newView = false;
      newAngle = "2";
    } else {
      newView = true;
      newAngle = "0";
    }
    this.setState({
      frontView: newView,
      angle: newAngle,
      imageA: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][this.state.stageA][newAngle],
      imageB: process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][this.state.stageB][newAngle]
    });
    console.log(this.state);
  }

  getImageA() {
    var img = process.env.PUBLIC_URL + '/images/' + this.state.folder + this.state.image_list[this.state.area][this.state.subArea][this.state.angle];
    console.log(img);
    return img;
  }

  changeCeiling() {
    this.setState({ includeCeiling: !this.state.includeCeiling });
    var newFolder;
    if (this.state.folder === "chunk/") {
      newFolder = "chunk_ceil/";
    } else {
      newFolder = "chunk/";
    }
    this.setState({
      folder: newFolder,
      imageA: process.env.PUBLIC_URL + '/images/' + newFolder + this.state.image_list[this.state.area][this.state.subArea][this.state.stageA][this.state.angle],
      imageB: process.env.PUBLIC_URL + '/images/' + newFolder + this.state.image_list[this.state.area][this.state.subArea][this.state.stageB][this.state.angle]
    });
  }

  render() {
    return (
      <React.Fragment>
        <Container maxWidth="md">
          <Box sx={{ my: 0 }}>
            <Typography variant="h6" align="center" component="h2" style={{ "paddingTop": "20px" }}>
              Visualization for Sub-areas
            </Typography>

            <Typography variant="body2" align="left" component="h3" style={{ "paddingTop": "0px" }}>
              Select the Area and the Sub-area used for visualization:
            </Typography>

            <Grid container spacing={3} style={{ "paddingTop": "00px" }}>
              <Grid item xs={4}>
                <Select components={animatedComponents} options={this.getOptionsArea()} value={this.toOptionsArea()} onChange={this.setArea.bind(this)} placeholder={"Area A"} />
              </Grid>
              <Grid item xs={4}>
                <Select options={this.getOptionsSubArea()} value={this.toOptionsSubArea()} defaultValue={this.state.subArea} onChange={this.setSubArea.bind(this)} placeholder={"Sub-area 1 - 4"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Switch checked={this.state.frontView} onChange={this.changeView.bind(this)} name="Front view" color="primary" />}
                  label={<Typography variant="body2" color="textSecondary">Front View</Typography>}
                />
              </Grid>
            </Grid>

            <Typography variant="body2" align="left" component="h3" style={{ "paddingTop": "15px" }}>
              Select the stage: (Multi-select 2 stages to view them in a sliding window.)
            </Typography>
            <Grid container spacing={3} style={{ "paddingTop": "0px" }}>
              <Grid item xs={8}>
                <Select isMulti components={animatedComponents} options={this.getOptionsStage()} value={this.state.stageOption} onChange={this.setStage.bind(this)} placeholder={"Stage 1"} />
              </Grid>
              <Grid item xs>
                <FormControlLabel labelPlacement="start"
                  control={<Switch checked={this.state.includeCeiling} onChange={this.changeCeiling.bind(this)} name="Front view" color="primary" />}
                  label={<Typography variant="body2" color="textSecondary">With Ceilings</Typography>}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <div width="100%" style={{ "backgroundColor": "white" }} >
          <Container maxWidth="lg" >
            <Box sx={{ my: 3 }}>
              <div align="center" style={{ height: "84vh", paddingTop: "1vh" }}>
                <div style={{ overflow: "hidden" }}>
                  {this.state.stageOption.length == 1 &&
                    <ReactCompareSliderImage alt="Image one" src={this.state.imageA} style={{
                      margin: "-125px auto 0",
                      overflow: "hidden",
                      height: '90vh',
                      width: '100%'
                    }} />
                  }

                  {this.state.stageOption.length == 2 &&
                    <ReactCompareSlider
                      handle={<ReactCompareSliderHandle style={{ color: 'white' }} linesStyle={{ height: '100%', width: 5 }} />}
                      boundsPadding={1}
                      itemOne={<ReactCompareSliderImage alt="Image one" src={this.state.imageA} />}
                      itemTwo={<ReactCompareSliderImage alt="Image two" src={this.state.imageB} />}
                      position={50}
                      style={{
                        margin: "-125px auto 0",
                        height: '90vh',
                        width: '100%'
                      }}
                    />
                  }

                  {this.state.stageOption.length == 0 &&
                    <Typography variant="body1" align="center" color="textSecondary" style={{ height: '80vh', width: '100%', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      Please select at least one stage.
                    </Typography>
                  }
                </div>
              </div>
            </Box>
          </Container>
        </div>
      </React.Fragment>
    )
  };
}

export default Viewer;