import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PotreeViewer from '../components/PotreeViewer';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';


const animatedComponents = makeAnimated();

const areaName = ['A', 'B', 'C', 'D', 'E', 'F'];
const numStage = [3, 6, 5, 2, 5, 6];

const baseSpeed = 6.5;
const speedOptions = [
  { value: 0.5 * baseSpeed, label: "0.5x" },
  { value: 0.75 * baseSpeed, label: "0.75x" },
  { value: 1.0 * baseSpeed, label: "1x" },
  { value: 1.5 * baseSpeed, label: "1.5x" },
  { value: 2 * baseSpeed, label: "2x" },
]

class Viewer3D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      area: 0,
      stage: 1,
      stageOptions: [],
      speedOption: { value: baseSpeed, label: "1x" },
      render_list: ["Bldg1_Stage1"],
    };
  }

  componentDidMount() {
    this.setState({ stageOptions: this.getStageOptions() });
    setTimeout(() => {
      this.setSpeed(speedOptions[2]);
    }, 500);
  }

  getOptionsArea() {
    var result = [];
    areaName.forEach((element, i) => {
      result.push({ value: i, label: "Area " + element });
    });
    return result;
  }

  toOptionsArea() {
    var i = this.state.area;
    return { value: i, label: "Area " + areaName[i] };
  }

  setArea(option) {
    this.setState({ area: option.value }, () => {
      this.setState({ stageOptions: this.getStageOptions() }, () => {
        this.setStage({ value: 1, label: "Stages 1" });
      }
      );
    });
  }

  getStageOptions() {
    var stageOptions = [];
    stageOptions.push({ value: 0, label: "All Stages " });
    for (var i = 1; i <= numStage[this.state.area]; i++) {
      stageOptions.push({ value: i, label: "Stage " + i });
    }
    return stageOptions;
  }

  toOptionsStage() {
    var i = this.state.stage;
    if (i == 0) {
      return { value: 0, label: "All Stages " };
    }
    else {
      return { value: i, label: "Stage " + i };
    }
  }

  setStage(option) {
    this.setState({ stage: option.value });
    let areaNumber = this.state.area + 1;
    if (option.value == 0) {
      // If the stage is 0, set the render_list to all the stages in the area
      var array = [...Array(numStage[this.state.area]).keys()];
      this.setState({ render_list: array.map((v, k) => { return `Bldg${areaNumber}_Stage${v + 1}` }) });
    }
    else {
      // If the stage is not 0, set the render_list to only the selected stage
      this.setState({ render_list: ["Bldg" + areaNumber + "_Stage" + option.value] });
    }
  }

  setSpeed(option) {
    this.setState({ speedOption: option });
  }


  render() {
    return (
      <React.Fragment>
        <Container maxWidth="md">
          <Box sx={{ my: 0 }} style={{ marginBottom: "-15px" }}>
            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "15px" }}>
              3D Visualization
            </Typography>

            <Typography variant="body2" align="left" style={{ "paddingTop": "10px" }}>
              Select the Area and the Stages used for visualization. The point clouds are downsampled to 1/2 of their original size for faster loading.
            </Typography>

            <Grid container spacing={2} style={{ "paddingTop": "10px" }}>
              <Grid item xs={4}>
                <Select components={animatedComponents} options={this.getOptionsArea()} value={this.toOptionsArea()} onChange={this.setArea.bind(this)} placeholder={"Area A"} />
              </Grid>
              <Grid item xs={4}>
                <Select components={animatedComponents} options={this.state.stageOptions} value={this.toOptionsStage()} onChange={this.setStage.bind(this)} placeholder={"Stage 1"} />
              </Grid>
              <Grid item xs={4}>
                <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                  <Grid item xs={7} md={8}>
                    <Typography variant="body2" color="textSecondary" align="right" style={{ "paddingRight": "10px" }}>
                      Moving Speed
                    </Typography>
                  </Grid>
                  <Grid item xs={5} md={4}>
                    <Select components={animatedComponents} label={"Speed"} options={speedOptions} value={this.state.speedOption} onChange={this.setSpeed.bind(this)} defaultValue={{ value: 6.9, label: "1x" }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Alert severity="info" align="left" style={{ "marginTop": "10px" }} >
              <Typography variant="body2"  >
                Drag mouse with <b>left click</b> to rotate and <b>right click</b> to pan. Press <b>W/A/S/D</b> to walk around.
                The loading may take a while.
              </Typography>
            </Alert>
          </Box>
        </Container>

        <div width="100%" style={{ "backgroundColor": "white" }} >
          <Container maxWidth="lg" >
            <Box sx={{ my: 3 }}>
              <div align="center" style={{ height: "750px", paddingTop: "10px" }}>
                <div style={{ overflow: "hidden" }}>
                  {this.state.render_list.length >= 1 &&
                    <PotreeViewer alt="Potree viewer" src={this.state.render_list} speed={this.state.speedOption.value} style={{
                      // margin: " auto 0",
                      overflow: "hidden",
                      height: '100%',
                      width: '100%'
                    }} />
                  }

                  {this.state.render_list.length == 0 &&
                    <Typography variant="body1" align="center" color="textSecondary" style={{ height: '80vh', width: '100%', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      Please select at least one stage.
                    </Typography>
                  }
                </div>
              </div>
            </Box>
          </Container>
        </div>
      </React.Fragment>
    )
  };
}

export default Viewer3D;