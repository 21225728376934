import React from 'react';
import * as THREE from "three";
import config from '../config.json';
import styled from "styled-components/macro";

const Wrapper = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  height: 720px;
  position: relative;
`;

const pointcloudBase = config.local.s3 + "/pointcloud/";
// const pointcloudBase = process.env.PUBLIC_URL + "/pointcloud/";

// import vanillaJS Potree libs, /!\ would be best with proper ES6 import
const Potree = window.Potree


export default class PointcloudNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.src = props.src;
    this.speed = props.speed;
    this.potreeContainerDiv = React.createRef();
  }

  render() {
    return (
      <div id="potree-root">
        <Wrapper ref={this.potreeContainerDiv} className={"potree_container"}>
          <div id="potree_render_area">
            {/* <div id="potree_toolbar"></div> */}
          </div>
          {/* <div id="potree_sidebar_container"> </div> */}
        </Wrapper>

      </div>
    )
  }

  componentDidMount() {
    this.viewerElem = this.potreeContainerDiv.current;
    this.viewer = new Potree.Viewer(this.viewerElem);
    this.viewer.setMoveSpeed(this.speed);
    this.updateViewer(true);
  }

  componentDidUpdate(prevProps) {
    this.speed = this.props.speed;
    this.viewer.setMoveSpeed(this.speed);

    if ((prevProps.src[0] !== this.props.src[0]) || (prevProps.src.length !== this.props.src.length)) {
      this.src = this.props.src;
      this.clearViewer();
      this.updateViewer();
    }
  }

  clearViewer() {
    this.viewer.scene.pointclouds.forEach(pc => {
      this.viewer.scene.scenePointCloud.remove(pc);
    });
    this.viewer.scene.pointclouds = [];
  }

  updateViewer(reset_window = false) {
    // initialize Potree viewer
    this.viewer.setEDLEnabled(true);
    this.viewer.setFOV(55);
    this.viewer.setPointBudget(2 * 1000 * 1000);
    this.viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    this.viewer.loadSettingsFromURL();
    this.viewer.setBackground("white");
    this.viewer.useHQ = true

    this.viewer.setControls(this.viewer.fpControls);

    this.viewer.loadGUI(() => {
      this.viewer.setLanguage('en');
      // document.getElementById("menu_appearance").next().show();
      // document.getElementById("menu_tools").next().show();
      // document.getElementById("menu_clipping").next().show();
      // this.viewer.toggleSidebar();
    });

    // Load and add point cloud to scene
    for (let i = 0; i < this.src.length; i++) {
      let name = this.src[i];
      // const cal_matrix = new THREE.Matrix4();
      // cal_matrix.set(...calibration[name]);
      // const position = new THREE.Vector3();
      // const quaternion = new THREE.Quaternion();
      // position.setFromMatrixPosition(cal_matrix);
      // quaternion.setFromRotationMatrix(cal_matrix.multiply(TZ));

      // console.log({ position, quaternion, cal_matrix, TZ });

      let load = function (e) {
        let pointcloud = e.pointcloud;
        let material = pointcloud.material;

        material.activeAttributeName = "rgba";
        material.minSize = 1;
        material.pointSizeType = Potree.PointSizeType.ADAPTIVE
        this.viewer.scene.addPointCloud(pointcloud);

        // console.log(pointcloud);
        // console.log(this.viewer.scene.view);
        // console.log(this.viewer.scene.getActiveCamera());

        if (reset_window) {
          // this.viewer.fitToScreen();
          this.viewer.scene.getActiveCamera().zoom = 1;
          // this.viewer.scene.getActiveCamera().position.set(0, 0, 0);
          // console.log(pointcloud.boundingBox.max);
          // this.viewer.scene.view.position.set(38, -15, 2.3);
          let center = pointcloud.position;
          this.viewer.scene.view.position.set(-2, -4, 1.2);
          this.viewer.scene.view.yaw = 5.1;
          this.viewer.scene.view.pitch = 0.15;

        }
      }.bind(this);
      Potree.loadPointCloud(
        pointcloudBase + name + "/metadata.json", name.split('_')[-1]
      ).then(load, e => console.err("ERROR: ", e));
    }
  }

}