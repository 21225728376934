import { useState } from 'react';
import { Container, Grid, Box, Typography, Divider, Collapse, IconButton, Link as MuiLink } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import LoadingButton from '../components/LoadingButton';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputForm from '../components/InputForm';
import { LinkItem, OauthMuiLink } from './Login.jsx';
import { Link, useNavigate } from 'react-router-dom';
import useToken from '../hocks/useToken';
import config from '../config';

// 👇 SignUp Schema with Zod
const signupSchema = object({
  username: string().min(1, 'Name is required').min(4, 'Username is too short').max(32, 'Username is too long'),
  email: string().min(1, 'Email is required').email('Email is invalid'),
  password1: string()
    .min(1, 'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  password2: string().min(1, 'Please confirm your password'),
}).refine((data) => data.password1 === data.password2, {
  path: ['password2'],
  message: 'Passwords do not match',
});

// Post request to register user and return response
async function registerUser(credentials) {
  return fetch(config.local.api_base + '/auth/registration/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

// Infer the Schema to get TypeScript Type
const SignupPage = () => {
  // Get state variables for error message and open state 
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);

  // Get a hook for login token
  const { token, setToken } = useToken();

  // Default Values
  const defaultValues = {
    username: '',
    email: '',
    password1: '',
    password2: '',
  };

  // Object containing all the methods returned by useForm
  const methods = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues,
  });

  // Form Handler
  let navigate = useNavigate();
  const onSubmitHandler = async (values) => {
    console.log(JSON.stringify(values, null, 4));
    const response = await registerUser(values);
    console.log(response);
    let errors = "";
    if (response.key === undefined) {
      if (response.username !== undefined) {
        errors += `Username issue: ${response.username.join(" ")}\n`;
      }
      if (response.email !== undefined) {
        errors += `Email issue: ${response.email.join(" ")}\n`;
      }
      if (response.password1 !== undefined) {
        errors += `Password issue: ${response.password1.join(" ")}\n`;
      }
      if (response.password2 !== undefined) {
        errors += `Password confirm: ${response.password2.join(" ")}\n`;
      }
      if (response.non_field_errors !== undefined) {
        errors += `Errors: ${response.non_field_errors.join(" ")}\n`;
      }
      setErrorMsg(errors);
    } else {
      setErrorMsg("");
      setOpen(true);
      setToken({ userKey: response.key, username: values.username });
      // navigate('/challenges');
    }
  };

  // Returned JSX
  return (
    <Container
      maxWidth={false}
      style={{ marginTop: "6rem", marginBottom: "6rem" }}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{ width: '100%', height: '100%' }}
      >
        <Grid
          item
          style={{ maxWidth: '28rem', width: '100%', backgroundColor: 'white' }}
        >
          <Grid
            container
            style={{
              boxShadow: '0 0 5px #ddd',
              padding: '1rem',
              paddingTop: '2rem',
            }}
          >
            <FormProvider {...methods}>
              <Grid
                item
                container
                justifyContent='space-between'
                sx={{
                  maxWidth: { sm: '45rem' },
                  marginInline: 'auto',
                }}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    style={{ padding: '1rem' }}
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                  >
                    <Typography
                      variant='h6'
                      component='h1'
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Welcome To NSS Challenges!
                    </Typography>
                    <InputForm
                      label='Username'
                      type='text'
                      name='username'
                      focused
                      required
                    />
                    <InputForm
                      label='Enter your email'
                      type='email'
                      name='email'
                      focused
                      required
                    />
                    <InputForm
                      type='password'
                      label='Password'
                      name='password1'
                      required
                      focused
                    />
                    <InputForm
                      type='password'
                      label='Confirm Password'
                      name='password2'
                      required
                      focused
                    />

                    <Collapse in={errorMsg !== ""}>
                      <Alert
                        severity="warning"
                        color='error'
                        sx={{ mb: 2 }}
                      >
                        {errorMsg}
                      </Alert>
                    </Collapse>

                    <Collapse in={open}>
                      <Alert
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ mb: 2 }}
                      >
                        Sign up successful! You can now <MuiLink href="/login">Login</MuiLink>.
                      </Alert>
                    </Collapse>


                    <LoadingButton
                      loading={false}
                      type='submit'
                      variant='outlined'
                      color='primary'
                      style={{
                        padding: '0.8rem',
                        marginTop: '1rem',
                        width: '70%',
                        marginInline: 'auto',
                        marginBottom: '2rem',
                      }}
                    >
                      Sign Up
                    </LoadingButton>
                  </Box>
                  <Divider variant='middle' />
                </Grid>
              </Grid>
              <Grid container justifyContent='center'>
                <Box style={{ margin: '1rem', textAlign: 'center' }}>
                  <Typography style={{ fontSize: '0.9rem', mb: '1rem' }}>
                    Already have an account? <LinkItem to='/login'>Login</LinkItem> here.
                  </Typography>
                </Box>
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignupPage;