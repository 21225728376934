import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Image from 'material-ui-image';
import Header from '../components/Header';
import Alert from '@material-ui/lab/Alert';
import AuthorsList from '../components/AuthorsList';
import { Card, CardContent, CardMedia, CardActionArea, CardActions, Button } from '@material-ui/core';
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';
import infoJson from '../data/info.json';
import { Divider } from '@material-ui/core';

const teaserImage = process.env.PUBLIC_URL + '/images/pipeline.jpg';
const buildingImage = process.env.PUBLIC_URL + '/images/all_building.jpg';
const compareImageOneA = process.env.PUBLIC_URL + '/images/1.jpg';
const compareImageOneB = process.env.PUBLIC_URL + '/images/5.jpg';
const registrationTasksImage = process.env.PUBLIC_URL + '/images/tasks.jpg';

const multiwayGraphA = process.env.PUBLIC_URL + '/images/Bldg2.png';
const multiwayGraphB = process.env.PUBLIC_URL + '/images/Bldg6.png';

const hightlightImage1 = process.env.PUBLIC_URL + '/images/highlight1.jpg';
const hightlightImage2 = process.env.PUBLIC_URL + '/images/highlight2.jpg';
const hightlightImage3 = process.env.PUBLIC_URL + '/images/highlight3.jpg';

export default function App() {
  return (
    <React.Fragment>
      <Header />

      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" align="center" component="h2" style={{ paddingBottom: "20px" }} >
            Highlights
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  image={hightlightImage1}
                  title="Highlight"
                  style={{ height: "200px" }}
                />
                <CardContent>
                  <Typography variant="h6" align="center" component="h2">
                    Large Indoor Scenes
                  </Typography>
                  <Typography variant="body1" align="center" color="textSecondary" component="p">
                    Collected from 6 construction sites, our large-scale indoor dataset covers more than 100,000 square meters of dynamic indoor scenes.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  image={hightlightImage2}
                  title="Highlight"
                  style={{ height: "200px" }}
                />
                <CardContent>
                  <Typography variant="h6" align="center" component="h2">
                    Spatiotemporal changes
                  </Typography>
                  <Typography variant="body1" align="center" color="textSecondary" component="p">
                    Our dataset covers a wide range of spatiotemporal changes, including drastic objects, occlusions, and appearance changes.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  image={hightlightImage3}
                  title="Highlight"
                  style={{ height: "200px" }}
                />
                <CardContent>
                  <Typography variant="h6" align="center" component="h2">
                    Multiway Registration
                  </Typography>
                  <Typography variant="body1" align="center" color="textSecondary" component="p">
                    Our dataset enables exploration of multiway registration over a pose graph of 1000+ point clouds, a challenging yet more realistic setting.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Box>
      </Container>

      <div width="100%" style={{ "backgroundColor": "white" }} >
        <Container maxWidth="md" >
          <Box sx={{ my: 4 }}>

            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "60px", "paddingBottom": "5px" }}>
              Pairwise and Multiway Registration
            </Typography>

            <Divider style={{ "marginBottom": "20px" }} />

            <Typography variant="body1" style={{ "paddingBottom": "20px" }}>
              Our benchmark is designed for evaluating the performance of registration algorithms in both pairwise and multiway settings under spatiotemporal changes.
              In the pairwise setting, the goal is to align a pair of point clouds, while in the multiway setting, the goal is to align a set of point clouds.
              These two settings are complementary to each other, and both are crucial for real-world applications.
            </Typography>

            <Image src={teaserImage} aspectRatio={1784 / 682} color={"none"} style={{ "marginTop": "10px", "marginBottom": "10px" }} />
            <Typography variant="overline" align="center" component="h2" >
              Registration pipeline
            </Typography>


            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "60px", "paddingBottom": "5px" }}>
              Temporal Changes
            </Typography>
            <Divider style={{ "marginBottom": "10px" }} />

            <Typography variant="body1" style={{ "paddingBottom": "20px" }}>
              NSS dataset is collected from real construction sites across months, which covers much longer temporal duration and more dramatic object movement, appearance, disappearance, and shape deformation.

              Our experiments show that the performance of existing registration algorithms drops significantly when the temporal duration increases.
            </Typography>

            <Alert severity="info">Slide the vertical line on the right example to find temporal changes — check it out!</Alert>


            <Container maxWidth="lg" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
              <ReactCompareSlider
                handle={<ReactCompareSliderHandle style={{ color: 'black' }} buttonStyle={{ height: "40px", width: "40px" }} linesStyle={{ height: '80%', width: 2 }} />}
                boundsPadding={1}
                itemOne={<ReactCompareSliderImage alt="Image one" src={compareImageOneA} style={{ width: "100%" }} />}
                itemTwo={<ReactCompareSliderImage alt="Image two" src={compareImageOneB} style={{ transform: 'scale(1, 1.005) translate(-1%, 0.4%)' }} />}
                position={45}
                style={{
                  height: '400px',
                  width: '100%'
                }}
              />
            </Container>


            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "60px", "paddingBottom": "5px" }}>
              Registration Tasks and Leaderboard
            </Typography>
            <Divider style={{ "marginBottom": "10px" }} />

            <Typography variant="body1" style={{ "paddingBottom": "20px" }}>
              We provide a set of registration tasks for evaluating the performance of registration algorithms:
              <b> Cross Area</b>, <b>Cross Stage</b>, and <b>Original</b>.
              The tasks are designed to evaluate the generalization ability of registration algorithms at different levels.
            </Typography>
            <Image src={registrationTasksImage} aspectRatio={2.8369304556} color={"none"} />

            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "60px", "paddingBottom": "5px" }}>
              Areas and Stages
            </Typography>
            <Divider style={{ "marginBottom": "10px" }} />

            <Typography variant="body1" style={{ "paddingBottom": "20px" }}>
              The NSS dataset captures more than 1.5 billion 3D points and 15,000 m<sup>2</sup> area from areas of educational, scientific, and office use.
              Each area covers approximately 2,500 m<sup>2</sup> in average and has 2–6 stages.
            </Typography>
            <Image src={buildingImage} aspectRatio={2.03} color={"none"} />

            <Typography variant="body1" style={{ "paddingTop": "20px" }}>
              You can find more visual samples of each area and each stage in the <Link href="/viewer">Viewer</Link>.
            </Typography>



            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "60px", "paddingBottom": "5px" }}>
              Video Demo
            </Typography>

            <Divider style={{ "marginBottom": "20px" }} />

            <Typography variant="body1" style={{ "paddingBottom": "20px" }}>
              Check out our video demo for a quick overview of our dataset! It includes point clouds captured from different stages of 2 construction sites. It is recorded using a VR headset. We will release the full VR app soon for you to explore our dataset in VR!
            </Typography>

            <div style={{ "align": "center", "margin": "auto", "width": "100%", "textAlign": "center" }}>
              <iframe width="600px" height="600px" src="https://www.youtube.com/embed/AyxK_oKM5V0?rel=0&amp;autoplay=0&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>


            {/* 

            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "100px", "paddingBottom": "5px" }}>
              Pose Graph
            </Typography>
            <Divider style={{ "marginBottom": "10px" }} />

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Image src={multiwayGraphA} aspectRatio={0.7} color={"none"} />
                <Typography variant="overline" align="center" component="h2" >
                  Area B
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Image src={multiwayGraphB} aspectRatio={0.7} color={"none"} />
                <Typography variant="overline" align="center" component="h2" >
                  Area F
                </Typography>
              </Grid>
            </Grid> */}

            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "60px", "paddingBottom": "5px" }}>
              Authors
            </Typography>
            <Divider style={{ "marginBottom": "10px" }} />

            <AuthorsList authors={infoJson.authors} />

            <Typography variant="body1" style={{ "paddingTop": "20px", "paddingBottom": "40px" }}>
              For any questions or concerns, please contact us at <Link href="mailto:nss.benchmark@gmail.com">nss.benchmark@gmail.com</Link>.
            </Typography>

          </Box>
        </Container>
      </div>
    </React.Fragment>
  );
}