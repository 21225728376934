import React, { useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Link as MuiLink,
  Divider,
  Collapse,
  IconButton,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { literal, object, string } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputForm from '../components/InputForm';
import LoadingButton from '../components/LoadingButton';
import styled from '@emotion/styled';
import config from '../config';
import useToken from '../hocks/useToken';

// Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
    text-decoration: none;
    color: #3683dc;
    &:hover {
      text-decoration: underline;
      color: #5ea1b6;
    }
  `;

// Styled Material UI Link Component
export const OauthMuiLink = styled(MuiLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f6f7;
    border-radius: 1;
    padding: 0.1rem 0;
    column-gap: 1rem;
    text-decoration: none;
    color: #393e45;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      background-color: #fff;
      box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
    }
  `;

// Login Schema with Zod
const loginSchema = object({
  username: string().min(1, 'Username is required').min(4, 'Username is too short'),
  password: string()
    .min(1, 'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  persistUser: literal(true).optional(),
});

// Post request to login user and return response
async function loginUser(credentials) {
  return fetch(config.local.api_base + '/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function LoginPage() {
  // Get state variables for error message and open state 
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);

  // Get a hook for login token
  const { token, setToken } = useToken();

  // Default Values
  const defaultValues = {
    username: '',
    password: '',
  };

  // The object returned from useForm Hook
  const methods = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  // Submit Handler
  let navigate = useNavigate();
  const onSubmitHandler = async (values) => {
    const response = await loginUser({
      username: values.username,
      password: values.password
    });
    if (response.token === undefined) {
      if (response.non_field_errors !== undefined) {
        setErrorMsg(response.non_field_errors[0]);
      } else {
        setErrorMsg("An error occured!");
        console.log(response);
      }
    } else {
      setErrorMsg("");
      setOpen(true);
      setToken({ userKey: response.token, username: values.username });
      // navigate('/challenges');
    }
  };

  // JSX to be rendered
  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        style={{ marginTop: "6rem", marginBottom: "6rem" }}
      >
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ width: '100%', height: '100%' }}
        >
          <Grid
            item
            style={{ maxWidth: '28rem', width: '100%', backgroundColor: 'white' }}
          >
            <FormProvider {...methods}>
              <Grid
                container
                style={{
                  boxShadow: '0 0 5px #ddd',
                  padding: '1rem',
                  paddingTop: '2rem',
                }}
              >
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  style={{
                    maxWidth: '25rem',
                    marginInline: 'auto',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      component='form'
                      noValidate
                      autoComplete='off'
                      style={{ padding: '1rem' }}
                      onSubmit={methods.handleSubmit(onSubmitHandler)}
                    >
                      <Typography
                        variant='h6'
                        component='h1'
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Log into your account
                      </Typography>

                      <InputForm
                        label='Username'
                        type='username'
                        name='username'
                        focused
                        required
                      />
                      <InputForm
                        type='password'
                        label='Password'
                        name='password'
                        required
                        focused
                      />

                      <Collapse in={errorMsg !== ""}>
                        <Alert
                          severity="warning"
                          color='error'
                          sx={{ mb: 2 }}
                        >
                          {errorMsg}
                        </Alert>
                      </Collapse>

                      <Collapse in={open}>
                        <Alert
                          severity="success"
                          sx={{ mb: 2 }}
                        >
                          Log in successful! You can go to <MuiLink href="/challenges">Challenges</MuiLink>.
                        </Alert>
                      </Collapse>

                      <LoadingButton
                        loading={false}
                        type='submit'
                        variant='outlined'
                        color='primary'
                        style={{
                          padding: '0.8rem',
                          marginTop: '1rem',
                          marginBottom: '2rem',
                          width: '70%',
                          marginInline: 'auto',
                        }}
                      >
                        Login
                      </LoadingButton>
                    </Box>
                    <Divider variant='middle' />
                  </Grid>
                </Grid>


                <Grid container justifyContent='center'>
                  <Box style={{ margin: '1rem', textAlign: 'center' }}>
                    <Typography style={{ fontSize: '0.9rem', marginBottom: '0.1rem' }}>
                      Need an account?{' '}
                      <LinkItem to='/signup'>Sign up</LinkItem> here.
                    </Typography>
                    <Typography style={{ fontSize: '0.9rem' }}>
                      Forgot your{' '}
                      <LinkItem to='/forgotPassword'>password?</LinkItem>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

// LoginPage.propTypes = {
//   setToken: PropTypes.func.isRequired
// };