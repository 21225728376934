import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Image from 'material-ui-image';
import ChallengeCard from '../components/ChallengeCard';
import HeaderSmall from '../components/HeaderSmall';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 26,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '10px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //alignItems: "center",
        //justifyContent: "center",
        //width: '100%',
        //margin: `${theme.spacing(1)}px auto`,
    },
    card: {
        //padding: theme.spacing(3),
        //margin: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        //direction: 'column',
        //margin: `${theme.spacing(1)}px auto`,
    },
}));


const metricsImage = process.env.PUBLIC_URL + '/images/metrics.jpg';
const pcdImage = process.env.PUBLIC_URL + '/images/pcd.jpg';
const depthImage = process.env.PUBLIC_URL + '/images/depth.jpg';
const meshImage = process.env.PUBLIC_URL + '/images/mesh.jpg';
const floorplanImage = process.env.PUBLIC_URL + '/images/floorplan.jpg';


export default function ChallengeOverview() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <React.Fragment>
            <HeaderSmall title={"Download"} />

            <div style={{ "backgroundColor": "white", "paddingTop": "20px", "paddingBottom": "50px" }} >

                <Container maxWidth="md">
                    <Box sx={{ my: 0 }}>

                        <Typography variant="body1" align="left" style={{ "paddingTop": "10px" }}>
                        </Typography>

                        <Typography variant="body2" align="left" style={{ "paddingTop": "10px" }}>
                        </Typography>


                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
                            The NSS dataset is provided for non-commercial, academic research purposes.
                            By downloading the dataset, you agree to the following terms of use.
                            To download the dataset, visit <Link href="https://redivis.com/datasets/0tn0-74havn4wj" target='_blank'>here</Link>.
                            We do not provide test set ground truth; data evaluation is hosted on our <Link href="https://eval.nothing-stands-still.com/" target='_blank'>evaluation server</Link>.
                        </Typography>

                        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ "marginTop": "20px" }}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography variant="body1" style={{ fontWeight: 700 }}>Terms of use</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <ul style={{ "paddingTop": "10px", "paddingLeft": "20px", "listStyleType": "dot", fontSize: "12pt" }}>
                                        <li>I will use the NSS dataset only for non-commercial, academic research purposes. </li>
                                        <li>I accept that the authors and their affiliated organizations make no representations or warranties regarding the dataset, including but not limited to warranties of non-infringement or fitness for a particular purpose. </li>
                                        <li>I accept full responsibility for my use of the NSS dataset and shall defend and indemnify the authors and their affiliated organizations and members of them, against any and all claims arising from my use of the dataset, including but not limited to my use of any copies of copyrighted data that I may create from the dataset. </li>
                                        <li>I will not redistribute the dataset in any way or format. Any new access to the dataset will be established through this form and/or the official method of dataset release determined by the authors. </li>
                                        <li>I accept that the authors reserve the right to terminate my access to the dataset at any time. </li>
                                        <li>If I am employed by a for-profit, commercial entity, my employer shall also be bound by these terms and conditions, and I hereby represent that I am fully authorized to enter into this agreement on behalf of my employer. </li>
                                        <li>I accept that any derivatives resulting from the utilization of this dataset are also subject to these terms and conditions. </li>
                                        <li>I acknowledge that I am personally liable for any violation of these terms. </li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "50px" }}>
                            Data
                        </Typography>

                        <Grid container spacing={2} style={{ "paddingTop": "20px" }}>
                            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                                <img src={pcdImage} style={{ "width": "100%" }} />
                            </Grid>
                            <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                                <Typography variant="h6" align="left" component="h3" style={{ "paddingTop": "0px" }}>
                                    Point clouds
                                </Typography>
                                <Typography variant="body1" align="left" style={{ "paddingTop": "10px" }}>
                                    The point clouds are stored in the PLY format with the z axis pointing upwards. The point clouds are stored in the following naming pattern (e.g., <code>BldgN_StageM_SpotX.ply</code>).
                                    There are 6 buildings (<code>Bldg1</code> to <code>Bldg6</code>), each with 2-6 stages (<code>Stage1</code> to <code>Stage6</code>). There are totally 4829 point clouds. All point clouds are voxel downsampled to 0.05m resolution.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Button variant="outlined" color="primary" href="/" target="_blank" style={{ "marginTop": "10px", "marginBottom": "10px" }}>
                            point clouds (3.4GB)
                        </Button>
                        <div> (coming soon) </div> */}

                        <Grid container spacing={2} style={{ "paddingTop": "20px" }}>
                            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                                <img src={depthImage} style={{ "width": "100%" }} />
                            </Grid>
                            <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                                <Typography variant="h6" align="left" component="h3" style={{ "paddingTop": "0px" }}>
                                    Depth panoramas (epirectangular projection)
                                </Typography>
                                <Typography variant="body1" align="left" style={{ "paddingTop": "10px" }}>
                                    The depth panoramas of each point clouds are stored in the PNG format with the epirectangular projection. The files are stored in the name of <code>BldgN_StageM_SpotX.png</code>.
                                    <br />The resolution of the depth panoramas is 3600 x 1800 px.
                                    The values of the depth are stored as a 16-bit unsigned integer. To convert the depth values to meters, divide the depth values by <code>6553.5</code>. For a complete point cloud generation by depth panoramas, please refer to this <Link href="https://github.com/GradientSpaces/nss-challenge/blob/main/scripts/pan_to_pcd.py" target='_blank'>script</Link>.

                                </Typography>
                            </Grid>
                        </Grid>


                        <Typography variant="body1" align="left" style={{ "paddingTop": "10px" }}>
                        </Typography>
                        {/* <Button variant="outlined" color="primary" href="/" target="_blank" style={{ "marginTop": "10px", "marginBottom": "10px" }}>
                            depth panoramas (12.6GB)
                        </Button>
                        <div> (coming soon) </div> */}

                        <Grid container spacing={2} style={{ "paddingTop": "20px" }}>
                            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                                <img src={floorplanImage} style={{ "width": "100%" }} />
                            </Grid>
                            <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                                <Typography variant="h6" align="left" component="h3" style={{ "paddingTop": "0px" }}>
                                    Floor plans and camera poses
                                </Typography>
                                <Typography variant="body1" align="left" style={{ "paddingTop": "10px" }}>
                                    The floor plans are stored in the PNG format. The resolution of the floor plans differs from each other. The files are stored in the following naming pattern, <code>BldgN_StageM.png</code>. This is only provided for reference and not used in the benchmark.

                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ "paddingTop": "20px" }}>
                            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                                <img src={meshImage} style={{ "width": "100%" }} />
                            </Grid>
                            <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                                <Typography variant="h6" align="left" component="h3" style={{ "paddingTop": "0px" }}>
                                    Raw data for meshes (with color)
                                </Typography>
                                <Typography variant="body1" align="left" style={{ "paddingTop": "10px" }}>
                                    The raw scanning data are stored in the OBJ format. The OBJ, MTL, and texture files are stored under <code>BldgN_StageM/mesh</code>. This is only provided for reference and not used in the benchmark.

                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
                        </Typography>
                        {/* <Button variant="outlined" color="primary" href="/" target="_blank" style={{ "marginTop": "10px", "marginBottom": "10px" }}>
                            floor plans (130MB)
                        </Button>
                        <div> (coming soon) </div> */}

                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
                        </Typography>
                        {/* <Button variant="outlined" color="primary" href="/" target="_blank" style={{ "marginTop": "10px", "marginBottom": "10px" }}>
                            Raw meshes (19GB)
                        </Button>
                        <div> (coming soon) </div> */}
                        <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
                            Pose Graph Annotations
                        </Typography>

                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "20px" }}>
                            Here are the annotations for <b>multi-way</b> point cloud registration, including our <Link href="/challenge" target='_blank'>challenges</Link>.
                        </Typography>

                        <Typography variant="h6" align="left" component="h3" style={{ "paddingTop": "10px" }}>
                            Format
                        </Typography>

                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
                            Since there is no odometry information due to the way the dataset was collected, in lieu of that we provide a pose graph for global and pairwise point cloud registration, where nodes represent the spatiotemporal fragments and edges denote spatial overlap.
                            The provided files are in JSON format and contain a list of pose graphs, each representing a specific building scene.
                            For each of the three dataset splits of <i>original</i>, <i>cross-area</i>, and <i>cross-split</i>, there are different pose-graphs JSON files to use.
                            Below is the format of the JSON file:

                            <pre>
                                <b>- list[dict]:</b>                       List of pose graphs.<br /><br />

                                &emsp;&emsp;<b>- name (str): </b>                    Name for the building scene, formatted as "BldgX_SceneN".<br /><br />

                                &emsp;&emsp;<b>- nodes (list[dict]):</b>             List of nodes for point clouds within the scene.<br />
                                &emsp;&emsp;&emsp;&emsp;- id (int):                     Identifier (ID) for the node within its scene.<br />
                                &emsp;&emsp;&emsp;&emsp;- name (str):                   Name of the point cloud file ("BldgX_StageY_SpotZ.ply").<br />
                                &emsp;&emsp;&emsp;&emsp;- tsfm (list[list[float]]):     4x4 transformation matrix of the pose of the point cloud<br />
                                &emsp;&emsp;&emsp;&emsp;                                in global coordinates.<br />
                                &emsp;&emsp;&emsp;&emsp;- building (str):               Building name, matching "X" in the node name.<br />
                                &emsp;&emsp;&emsp;&emsp;- stage (str):                  Temporal stage, matching "Y" in the node name.<br />
                                &emsp;&emsp;&emsp;&emsp;- spot (str):                   Spot number,  matching "Z" in the node name.<br />
                                &emsp;&emsp;&emsp;&emsp;- points (int):                 Number of points in the point cloud.<br /><br />

                                &emsp;&emsp;<b>- edges (list[dict], optional):</b> &emsp;&emsp;List of edges representing pairwise pose between nodes.<br />
                                &emsp;&emsp;&emsp;&emsp;- source (int):                 Node ID of the source point cloud.<br />
                                &emsp;&emsp;&emsp;&emsp;- target (int):                 Node ID of the target point cloud.<br />
                                &emsp;&emsp;&emsp;&emsp;- tsfm (list[list[float]]):     4x4 transformation matrix of the relative pose from the<br />
                                &emsp;&emsp;&emsp;&emsp;                                source to the target.<br />
                                &emsp;&emsp;&emsp;&emsp;- overlap (float):              Overlap ratio between the source and target, ranging from<br />
                                &emsp;&emsp;&emsp;&emsp;                                0.0 to 1.0.<br />
                                &emsp;&emsp;&emsp;&emsp;- temporal_change (float):      Temporal change ratio indicating the amount of temporal<br />
                                &emsp;&emsp;&emsp;&emsp;                                change between source and target, ranging from 0.0 to 1.0.<br />
                                &emsp;&emsp;&emsp;&emsp;- same_stage (bool):            Indicates whether the source and target come from the same<br />
                                &emsp;&emsp;&emsp;&emsp;                                temporal stage.<br />

                            </pre>

                            In the training and validation sets, the <i>tsfm</i> fields in the nodes and edges are provided.
                            In the testing set, the <i>tsfm</i> fields are omitted and need to be predicted.
                        </Typography>

                        <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "50px" }}>
                            Pairwise Pose Annotations
                        </Typography>

                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "20px" }}>
                            Here are the annotations for <b>pairwise</b> point cloud registration.
                        </Typography>

                        <Typography variant="h6" align="left" component="h3" style={{ "paddingTop": "10px" }}>
                            Format
                        </Typography>

                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
                            The files are stored in the following naming pattern, <code>task_name/{'{'}train, val{'}'}.json</code>.
                            The task name may be one of <code>cross_stage</code>, <code>cross_area</code>, or <code>original</code>. All these annotations share the same format and point cloud data.
                            The JSON file contains a list of ground truth registration results, each of which is a dictionary with the following keys.

                            <pre>
                                - building (str):                   building name<br />
                                - source_stage (str):               source stage number <br />
                                - target_stage (str):               target stage number <br />
                                - same_stage (bool):                whether the source and the target are from the same stage<br />
                                - source (str):                     source point cloud name<br />
                                - target (str):                     target point cloud name<br />
                                - tsfm (list[list[float]]):         4-by-4 transformation matrix from the source to the target<br />
                                - tsfm_global (list[list[float]]):  4-by-4 transformation matrix of global pose<br />
                                - overlap (float):                  overlap ratio (0.0 - 1.0) between source and target<br />
                                - temporal_change (float, opt):     temporal change ratio (0.0 - 1.0) between source and target<br />
                                - curvature (float):                averaged curvature (0.0 - 1.0) for source and target points<br />
                                &emsp;&emsp;&emsp;&emsp;                                in the overlapping region<br />
                            </pre>
                        </Typography>

                        {/* <Button variant="outlined" color="primary" href="/" target="_blank" style={{ "marginTop": "10px", "marginBottom": "10px" }}>
                            Annotations (13MB)
                        </Button>
                        <div> (coming soon) </div> */}

                        <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "50px" }}>
                            Notes on the metrics
                        </Typography>

                        <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
                            The overlap ratio (OR) is defined as the ratio of the number of overlapping points to the number of points in the source point cloud.
                            <p>The temporal change ratio (TCR) is defined as the ratio of the number of points that are not in the source point cloud but in the target point cloud to the number of points in the <b>overlapped region</b>.
                                The TCR is only valid when the source and the target are from the different stages.
                            </p>

                        </Typography>
                        <Container maxWidth="sm">

                            <Image src={metricsImage} aspectRatio={1.355} color={"none"} width={"10%"} />
                        </Container>

                    </Box>
                </Container>

                `
            </div>
        </React.Fragment>
    );
}
