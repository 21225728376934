
import * as React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Typography from '@material-ui/core/Typography';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './pages/App';
import Viewer from './pages/Viewer';
import Viewer3D from './pages/Viewer3D';
import ChallengeOverview from './pages/ChallengeOverview'
import Leaderboard from './pages/Leaderboard';
import Challenge from './pages/Challenge'
import Download from './pages/Download'
import TopBar from './components/TopBar';
import theme from './theme';
import config from './config.json';
import LoginPage from './pages/Login.jsx';
import SignupPage from './pages/Signup.jsx';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ "paddingTop": "20px", "paddingBottom": "30px" }}>
      {'Copyright © '}
      <Link to={process.env.PUBLIC_URL}>NSS Benchmark</Link>
      {' '} {new Date().getFullYear()} {'.'}
    </Typography>
  );
}

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <TopBar />

      <Routes>
        <Route path="/viewer" element={<Viewer />} />
        <Route path="/viewer3d" element={<Viewer3D />} />
        <Route path="/download" element={<Download />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/challenge/" element={<Challenge />} />
        <Route path="/" element={<App />} />
      </Routes>

      <Copyright />

    </ThemeProvider>
  </Router>,
  document.querySelector('#root'),
);