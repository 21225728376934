import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Image from 'material-ui-image';
import ChallengeCard from '../components/ChallengeCard';
import HeaderSmall from '../components/HeaderSmall';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //alignItems: "center",
    //justifyContent: "center",
    //width: '100%',
    //margin: `${theme.spacing(1)}px auto`,
  },
  card: {
    //padding: theme.spacing(3),
    //margin: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    //direction: 'column',
    //margin: `${theme.spacing(1)}px auto`,
  },
}));

const challenges = [
  { title: "Spatiotemporal pairwise registration", description: "Learning to register a pair of point clouds with large spatio-temporal discrepancy.", link: "https://www.google.com/" },
  { title: "Spatiotemporal multiway registration", description: "Learning to register a group of point clouds with large spatio-temporal discrepancy.", link: "https://www.google.com/" },
  { title: "Overlapped point clouds detection", description: "Learning to detect whether a given pair of point clouds are overlapped spatially regardless of their temporal changes", link: "https://www.google.com/" },
];


export default function ChallengeOverview() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <HeaderSmall title={"Challenges"} />

      <div style={{ "backgroundColor": "white", "paddingTop": "20px", "paddingBottom": "50px" }} >

        <Container maxWidth="md">
          <Box sx={{ my: 0 }}>
            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "20px" }}>
              Ongoing Challenges
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The following challenges are currently open for submissions. Click on a challenge to learn more.
            </Typography>

          </Box>
        </Container>

        <div className={classes.root} style={{ "paddingTop": "20px" }}>

          <Container maxWidth="md">
            <Grid container justifyContent="space-evenly" direction="row" alignItems="center" align="left" spacing={3}>
              {
                challenges.map((d, index) => (
                  <Grid item xs={4}>
                    <ChallengeCard className={classes.card} title={d.title} description={d.description} ></ChallengeCard>
                  </Grid>
                ))
              }
            </Grid>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "20px" }}>
              We will invite the winners to present their work at the xxx (TBD) conference.
            </Typography>

          </Container>

        </div>
      </div>
    </React.Fragment>
  );
}
