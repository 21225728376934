import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LazyHero from 'react-lazy-hero';


const backgroundImage = process.env.PUBLIC_URL + '/images/hover.jpg';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 0),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  background: {
    backgroundImage: backgroundImage,
    backgroundColor: '#fefefe', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
}));

export default function Header() {
  const classes = useStyles();
  return (
    <div className={classes.heroContent}>
      <LazyHero imageSrc={backgroundImage} className={classes.background} style={{ 'opacity': 1.0, minHeight: '320px', height: '450px', "parallaxOffset": "120px" }}>

        {/* Increase the network loading priority of the background image. */}

        <Typography color="textPrimary" align="center" variant="h2" marked="center">
          Nothing Stands Still
        </Typography>

        <Typography color="inherit" align="center" variant="h5" className={classes.h5} style={{ paddingBottom: '40px' }}>
          A spatiotemporal benchmark on 3D point cloud registration
        </Typography>

        <Button
          color="primary"
          variant="outlined"
          size="large"
          className={classes.button}
          component="a"
          href="/download"
        >
          Download
        </Button>

        <Button
          variant="outlined"
          size="large"
          className={classes.button}
          component="a"
          href="/viewer"
          style={{ marginLeft: "30px" }}
        >
          Visualization
        </Button>

        {/* <Typography variant="body1" color="inherit" className={classes.more}>
          Available soon...
        </Typography> */}

      </LazyHero>
    </div>
  );
}