import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Image from 'material-ui-image';
import HeaderSmall from '../components/HeaderSmall';

import { DataGrid } from '@mui/x-data-grid';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import reg from '../data/reg.json';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1875ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1875ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '90%',
      backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));


function RegTable(props) {
  const columns = [
    // {
    //   field: 'id',
    //   headerName: '',
    //   width: 0,
    //   editable: false,
    // },
    {
      field: 'method',
      headerName: 'Method',
      width: 200,
      editable: false,
    },
    {
      field: 'recall_all',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>Recall <br /> [%]</b>
        </div >
      ),
    },
    {
      field: 'rte_all',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>RTE <br /> [m]</b>
        </div >
      ),
    },
    {
      field: 'rre_all',
      headerName: 'RRE [deg]',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>RRE <br /> [deg]</b>
        </div >
      ),
    },
    {
      field: 'recall_same',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>Recall  <br /> [%]</b>
        </div >
      ),
    },
    {
      field: 'rte_same',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>RTE <br /> [m]</b>
        </div >
      ),
    },
    {
      field: 'rre_same',
      headerName: 'RRE [deg]',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>RRE <br /> [deg]</b>
        </div >
      ),
    },
    {
      field: 'recall_diff',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>Recall <br /> [%]</b>
        </div >
      ),
    },
    {
      field: 'rte_diff',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>RTE <br /> [m]</b>
        </div >
      ),
    },
    {
      field: 'rre_diff',
      headerName: 'RRE [deg]',
      width: 75,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: "1.0" }} >
          <b>RRE <br /> [deg]</b>
        </div >
      ),
    },
  ];
  return (
    <div style={{ width: '100%', height: "360px" }}>
      <DataGrid
        rows={props.rows}
        columns={columns}
        autoPageSize
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooterPagination
        columnHeaderHeight={40}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={
          [
            { groupId: 'All pairs', children: [{ field: "recall_all" }, { field: "rte_all" }, { field: "rre_all" }] },
            { groupId: 'Same-stage pairs', children: [{ field: "recall_same" }, { field: "rte_same" }, { field: "rre_same" }] },
            { groupId: 'Different-stage pairs', children: [{ field: "recall_diff" }, { field: "rte_diff" }, { field: "rre_diff" }] },
          ]
        }
        rowHeight={35}
        disableSelectionOnClick
        style={{ border: "none", fontSize: "12px" }}
      />
    </div>
  );
}

export default function Leaderboard() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <HeaderSmall title={"Leaderboard"} />

      <div width="75%" style={{ "backgroundColor": "white" }} >

        <Container maxWidth="md">
          <Box sx={{ my: 0 }}>
            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "60px" }}>
              Spatiotemporal Pairwise Registration
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              We benchmark the performance of recent registration methods on our dataset. The results are shown below and are updated regularly.
            </Typography>

            <div className={classes.root}>
              <div className={classes.demo1}>
                <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                  <AntTab label="Cross Area" />
                  <AntTab label="Cross Stage" />
                  <AntTab label="Original" />
                </AntTabs>
                {value == 0 && <RegTable rows={reg.cross_area} />}
                {value == 1 && <RegTable rows={reg.cross_stage} />}
                {value == 2 && <RegTable rows={reg.original} />}
              </div>
            </div>


          </Box>
        </Container>

      </div>
    </React.Fragment>
  );
}